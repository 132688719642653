<!-- Localized -->
<template>
<ModalContent
  name="notes"
  ref="modal"
  width="60%"
  height="62%"
  class="rounded-full"
  :title="$t('components.sc_journey.note')"
  v-bind="$attrs"
  v-on="$listeners"
  :showFooter="false"
>
  <template #title>
    <NotesIcon />
    {{ $t('components.sc_journey.note') }}
  </template>
  <template #content>
    <div class="form-wrapper h-full">
      <div class="form-container flex flex-col gap-4 h-full">
        <div class="radio-group flex items-center justify-end gap-2">
          <span
            class="radio-control radio-control__radio1"
            :style="[notes.type === 'internal' ? {'background-color': '#0d69d5'} : {'background-color': '#d7d7d7'}]"
            v-if="checkPermission('case.actions_internal')"
          >
            <label
              for="internalNote"
              class="radio-control__label text-white-text font-semibold capitalize"
            >{{ $t('components.sc_journey.internal_note') }}</label>
            <input
              class="ml-1 cursor-pointer"
              type="radio"
              value="internal"
              id="internalNote"
              v-model="notes.type"
            />
          </span>
          <span
            class="radio-control radio-control__radio2"
            :style="[notes.type === 'customer' ? {'background-color': '#0d69d5'} : {'background-color': '#d7d7d7'}]"
            v-if="checkPermission('case.actions_external')"
          >
            <label
              for="customerNote"
              class="radio-control__label text-white-text font-semibold capitalize"
            >
              <span class="text-sm">{{ $t('components.sc_journey.customer_note') }}</span>
              <span class="flex m-0 p-0" style="font-size: 10px">
                {{ $t('components.sc_journey.will_be_visible_to_client') }}
              </span>
            </label>
            <input
              class="ml-1 cursor-pointer"
              type="radio"
              value="customer"
              id="customerNote"
              v-model="notes.type"
            />
          </span>
        </div>
        <div class="note-text-area flex-1">
          <textarea
            class="text-area-control"
            :placeholder="$t('components.sc_journey.type_your_text_here')"
            v-model="notes.note"
          ></textarea>
        </div>
      </div>
    </div>
  </template>
  <template>
    <footer class="flex items-center justify-between mb-0 pr-6 w-full bg-card-bg">
      <div
        class="footer-left-actions h-14 border-none py-4 px-9 cursor-pointer rounded text-sm flex items-center gap-4 bg-primary text-white-text"
      >
        <label
          for="notes_attachments"
          class="cursor-pointer relative w-8 h-8 grid place-content-center"
        >
          <EncloseIcon />
          <span
            v-if="notes && notes.attachments"
            class="absolute -top-1 right-0 bg-white text-black rounded-full w-3 h-3 grid place-content-center"
          >{{ notes.attachments.length }}</span>
        </label>

        <input
          @change="fetchFileName($event, 'notes_attachments')" 
          id="notes_attachments"
          type="file"
          name="notes_attachments"
          multiple="multiple"
          class="hidden"
        />
        <span>
          <DelIcon @click="resetForm" />
        </span>
      </div>
      <div class="flex items-center" v-if="isUserPermission">
        <div class="flex items-center">
          <input
            id="link-checkbox"
            type="checkbox"
            v-model="actions.task"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 cursor-pointer"
          >
          <label
            for="link-checkbox"
            class="cursor-pointer ml-2 text-sm font-medium text-gray-900"
          >{{ $t('components.sc_journey.create_task') }}</label>
        </div>
        <date-picker
          v-model="actions.date"
          class="ml-4 w-20"
          :placeholder="$t('components.sc_journey.date')"
        />
        <date-picker
          v-model="actions.time"
          class="ml-4"
          type="time"
          :placeholder="$t('components.sc_journey.time')"
          :show-minute="false"
          :show-second="false"
          format="HH"
          popup-class="time-picker"
          ref="datepicker"
          @change="handleChange"
        />
        <dv-dropdown
          class="ml-4"
          width="110px"
          :placeholder="$t('components.sc_journey.assignee')"
          :colors="{
          bg: '#fff',
          text: '#282828',
          border: '#d3d3d3',
          svg: '#0D69D5',
          }"
          v-model="actions.task_assignee" 
          :options="assigneeOptions"
          openDirection="top"
          :config="{'label': 'user_name','track-by': 'id'}"
          />
      </div>
      <div class="flex items-center" v-if="isUserPermission || checkPermission('case.actions_external')">
        <loader v-show="isLoading" class="mr-2"/>
        <neo-button
          :text="$t('components.sc_journey.save_note')"
          type="submit"
          :disabled="idBtnDisable"
          class="btn-primary w-32 text-sm border-none font-semibold rounded-xl px-4 py-2"
          @click="submitForm"
        >
        </neo-button>
      </div>
    </footer>
  </template>
  <!-- <div class="flex flex-col justify-between min-h-full h-full bg-white">
  </div> -->
</ModalContent>
</template>

<script>
// TODO reusable components to @shared
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import DvDropdown from '@/components/dropdown-base';
import Button from "@/components/button/index.vue";

import EncloseIcon from "@/assets/icons/profile-view/enclosure-icon-white.svg";
import DelIcon from "@/assets/icons/svg/delete-icon-white.svg";
import NotesIcon from "@/assets/icons/profile-view/wnotes.svg";
import { checkPermission } from "@shared/utils/functions";

const ModalContent = () => import("@shared/modal-content");
const Loader = () => import("@/components/loader");

export default {
  name: 'note-modal',
  components: {
    EncloseIcon,
    DelIcon,
    DatePicker,
    DvDropdown,
    NotesIcon,
		"neo-button": Button,
    ModalContent,
    Loader
  },
  props: {
    notes: {
      type: Object,
      required: true,
    },
    actions: {
      type: Object,
      required: true,
    },
    assigneeOptions: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isClient: {
      type: Boolean,
      default: false  
    }
  },
  data() {
    return {}
  },
  computed: {
    isUserPermission(){
      return this.isClient ? true : checkPermission('case.actions_internal');
    },
    idBtnDisable(){
      if(!this.notes.type || !this.notes.note || this.isLoading) return true
      else if(this.actions.task && (!this.actions.task_assignee || !this.actions.time || !this.actions.date)) return true
      // else if((this.actions.task_assignee || this.actions.time || this.actions.date) && !this.actions.task ) return true
      else return false
    }
  },
  // mounted(){
  //     this.closeModal();
  // },
  methods: {
    checkPermission,
    closeModal() {
      this.$modal.hide('notes');
    },
    submitForm() {
      this.$emit('submit', this.closeModal);
    },
    resetForm() {
      this.$emit('reset');
    },
		fetchFileName(event) {
			this.$emit('change:file', event);
		},
    handleChange(){
      this.$refs.datepicker.closePopup();
    }
  }
}
</script>

<style lang="scss" scoped>

// TODO check if all necessary styles are here
// TODO move common styles to the wrapper component

.gray-bg {
  background-color: #f2f2f2;
}
.footer-left-actions {
  @apply bg-primary;
  border-top-right-radius: 24px;
}
.statusList {
  padding: 12px 16px;
  text-align: left;
  vertical-align: middle;
  transition: background-color 300ms, border-right 100ms;
  position: relative;
  &:hover {
    background-color: #cce3ff;
  }
  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: #3b2cd4;
    right: 0;
    top: 0;
  }
  &__roles {
    padding-left: 1rem;
    padding-right: 3.5rem;
  }
}
.form-wrapper {
  .radio-control {
    max-height: 3rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    background-color: #d7d7d7;
    cursor: pointer;
    &__radio1 {
      padding: 9px 10px 12px 16px;
    }
    &__radio2 {
      padding: 2px 12px 3px 16px;
    }

    &__label {
      cursor: pointer;
    }
    input[type="radio"] {
      appearance: none;
      background-color: #9f9f9f;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input[type="radio"]:checked {
      background-color: white !important;
    }
    input[type="radio"]::before {
      content: "";
      width: 0.65rem;
      height: 0.65rem;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #0d69d5;
    }
    input[type="radio"]:checked::before {
      transform: scale(1);
    }
  }
}

.mx-datepicker {
  width: 110px !important;
}
::v-deep .mx-input {
  height: 1.75rem !important;
  border-radius: 0.5rem !important;
}

.date-error ::v-deep .mx-input {
  border: 1px solid #F44336 !important;
}
.time-error ::v-deep .mx-input {
  border: 1px solid #F44336 !important;
}
</style>